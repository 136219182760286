import { io } from 'socket.io-client';

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

class SocketClient {
  socket: any;

  connect() {
    this.socket = io(SOCKET_URL, {
      transports: ['websocket'],
      query: {
        token: localStorage.getItem('token')
      }
    });

    return new Promise((resolve, reject) => {
      this.socket.on('connect', () => resolve('Connected'));
      this.socket.on('connect_error', (error: any) => reject(error));
    });
  }

  disconnect() {
    return new Promise((resolve) => {
      this.socket.disconnect(() => {
        this.socket = null;
        resolve('Disconnected');
      });
    });
  }

  emit(event: any, data?: any) {
    return new Promise((resolve, reject) => {
      if (!this.socket) return reject('No socket connection.');

      return this.socket.emit(event, data, (response: any) => {
        // Response is the optional callback that you can use with socket.io in every request. See 1 above.
        if (response.error) {
          console.error(response.error);
          return reject(response.error);
        }

        return resolve('Emitted');
      });
    });
  }

  on(event: any, fun: any) {
    // No promise is needed here, but we're expecting one in the middleware.
    return new Promise((resolve, reject) => {
      if (!this.socket) return reject('No socket connection.');

      this.socket.on(event, fun);
      resolve('Listening');
    });
  }
}

export default SocketClient;