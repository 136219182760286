import { useRef } from "react";
import styles from "../Styles/Input.module.css";

interface Props {
  name: string;
  type?: string;
  state: string;
  setState: any;
  [key: string]: any;
}

function Input({ name, state, setState, type = "text", ...props }: Props) {
  const inputRef = useRef<any>();

  return (
    <div className={styles.formInput}>
      <label>{name}</label>
      {type === "select" ? (
        <select
          onChange={(e) => setState(e.target.value)}
        >
          {props?.options?.map((option: any) => (
            <option
              key={option.value}
              value={option.value}
              selected={option.value === state}
            >
              {option.name}
            </option>
          ))}
        </select>
      ) : type === "datetime-local" ? (
        <div style={{ position: "relative", display: "inline-block" }}>
          <input
            ref={inputRef}
            value={state}
            onChange={(e) => setState(e.target.value)}
            type={type}
          />
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
            onClick={() => inputRef.current!.showPicker()}
          ></div>
        </div>
      ) : type !== "file" ? (
        <input
          value={state}
          onChange={(e) => setState(e.target.value)}
          type={type}
          {...props}
        />
      ) : (
        <input onChange={(e) => setState(e.target.files![0])} type={type} {...props} />
      )}
    </div>
  );
}

export default Input;
