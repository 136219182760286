import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import axios from 'axios';

import Home from './Pages/Home';
import Playlists from './Pages/Playlists';
import Login from './Pages/Login';
import Messages from './Pages/Messages';
import PlaylistAdd from './Pages/PlaylistAdd';
import PlaylistEdit from './Pages/PlaylistEdit';
import SongAdd from './Pages/SongAdd';
import SongEdit from './Pages/SongEdit';
import MessageAdd from './Pages/MessageAdd';
import MessageEdit from './Pages/MessageEdit';
import Users from './Pages/Users/Users';
import UsersCreation from './Pages/Users/UsersCreation';
import UserEdit from './Pages/Users/UserEdit';
import AssignPlaylists from './Pages/Users/AssignPlaylists';

import PrivateRoute from './Components/PrivateRoute';

axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? 'https://musicbackend.myzent.com/api' : 'http://localhost:8080/api';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PrivateRoute />}>
          <Route index element={<Home />} />
          <Route path="playlists">
            <Route index element={<Playlists />} />
            <Route path="add" element={<PlaylistAdd />} />
            <Route path=":slug">
              <Route path="edit" element={<PlaylistEdit />} />
            </Route>
            <Route path=":id/songs">
              <Route path="add" element={<SongAdd />} />
              <Route path=":songId/edit" element={<SongEdit />} />
            </Route>
          </Route>
          <Route path="/messages">
            <Route index element={<Messages />} />
            <Route path="add" element={<MessageAdd />} />
            <Route path=":id/edit" element={<MessageEdit />} />
          </Route>
          <Route path="/users">
            <Route index element={<Users />} />
            <Route path="add" element={<UsersCreation />} />
            <Route path=":id/edit" element={<UserEdit />} />
            <Route path=":id/playlists" element={<AssignPlaylists />} />
          </Route>
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
