import { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import Input from "../Components/Input";
import { Link, useNavigate } from "react-router-dom";
import { setAuth } from "../Store/authSlice";
import styles from "../Styles/Login.module.css";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState<String | null>(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const login = async (e: any) => {
    e.preventDefault();
    try {
      const { data } = await axios.post("/users/login", {
        email,
        password,
      });

      if (data.role === "admin") {
        const token = data.token;
        dispatch(setAuth({ token }));
        window.open('/', '_self');
      } else {
        setError("Invalid Credentials");
        console.error(e);
      }
    } catch (e) {
      setError("Invalid Credentials");
      console.error(e);
    }
  };

  return (
    <div className={styles.loginPage}>
      <header>
        <h1>
          <Link to="/">MyZent</Link>
        </h1>
      </header>
      <div className={styles.pageBody}>
        <div className={styles.loginContainer}>
          <h1 className={styles.title}>Admin Login</h1>
          {error && <div className={styles.error}>{error}</div>}
          <form onSubmit={login}>
            <div className={styles.inputs}>
              <Input name="Email" type="email" state={email} setState={setEmail} />
              <Input
                name="Password"
                type="password"
                state={password}
                setState={setPassword}
              />
            </div>
            <button type="submit" className={styles.submitButton}>Login</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
