import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { socketClient } from '../Layouts/LoggedInLayout';
import { AppDispatch } from '.';

const initialState = {
  connectionStatus: '',
  usersStatus: [],
  gettingUserDataStatus: '',
};

export const connectToSocket = createAsyncThunk(
  'connectToSocket',
  async (_, { rejectWithValue }) => {
    try {
      const response = await socketClient.connect();
      if (!socketClient.socket.connected) {
        throw new Error('Socket connection failed.');
      }
      console.log('Connected to socket:', response);
      return response;
    } catch (error: any) {
      console.error('Socket connection error:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const disconnectFromSocket = createAsyncThunk('disconnectFromSocket', async function () {
  return await socketClient.disconnect();
});

export const usersData = createAsyncThunk(
  'usersData',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      if (!socketClient.socket || !socketClient.socket.connected) {
        throw new Error('No socket connection.');
      }

      await new Promise((resolve, reject) => {
        socketClient.on('user_statuses', (user_statuses: any) => {
          dispatch({
            type: 'socket/saveUsersData',
            payload: { usersData: user_statuses },
          });
          resolve(user_statuses); // Resolve after receiving the data
        });
      });
    } catch (error: any) {
      console.error('Socket error:', error);
      return rejectWithValue(error.message);
    }
  }
);

const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    saveUsersData: (state: any, action: any) => {
      state.usersStatus = action.payload.usersData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(connectToSocket.pending, (state) => {
      state.connectionStatus = 'connecting';
    });
    builder.addCase(connectToSocket.fulfilled, (state) => {
      state.connectionStatus = 'connected';
    });
    builder.addCase(connectToSocket.rejected, (state) => {
      state.connectionStatus = 'connection failed';
    });
    builder.addCase(disconnectFromSocket.pending, (state) => {
      state.connectionStatus = 'disconnecting';
    });
    builder.addCase(disconnectFromSocket.fulfilled, (state) => {
      state.connectionStatus = 'disconnected';
    });
    builder.addCase(disconnectFromSocket.rejected, (state) => {
      state.connectionStatus = 'disconnection failed';
    });
    builder.addCase(usersData.pending, (state) => {
      state.gettingUserDataStatus = 'pending';
    });
    builder.addCase(usersData.fulfilled, (state) => {
      state.gettingUserDataStatus = 'fulfilled';
    });
    builder.addCase(usersData.rejected, (state, action) => {
      console.error('Fetching user data - failed', action.error.message);
      state.gettingUserDataStatus = 'connection failed';
    });
  },
});

export default socketSlice.reducer;