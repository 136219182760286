import React, { useEffect } from 'react'
import SocketClient from '../Services/SocketClient';
import { useDispatch } from 'react-redux';
import { connectToSocket, disconnectFromSocket, usersData } from '../Store/socketSlice';
import { useSelector } from 'react-redux';
import { AppDispatch } from '../Store';

interface Props {
  children: any
}

export const socketClient = new SocketClient();

function LoggedInLayout({ children }: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const { connectionStatus } = useSelector((state: any) => state.socket);
  
  useEffect(() => {
    // @ts-ignore
    const fetchData = async () => {
      try {
        await dispatch(connectToSocket()).unwrap();
        await dispatch(usersData()).unwrap();
      } catch (err) {
        console.error('Error in useEffect:', err);
      }
    };

    fetchData();

    return () => {
      // @ts-ignore
      if (connectionStatus === 'connected') {
        // @ts-ignore
        dispatch(disconnectFromSocket());
      }
    };
    //eslint-disable-next-line
  }, [dispatch]);

  return (
    <div>{children}</div>
  )
}

export default LoggedInLayout